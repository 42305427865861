import {sharedGlobalTexts} from "../shared-components/styles/globalTexts"

/**
 * These are default texts
 */
export const defaultTexts = {
    // Insert shared global texts
    ...sharedGlobalTexts,

    // HOME PAGE
    // Texts
    homeHeaderTitle: "Bienvenue sur votre Espace Financeur Domiserve",
    homeHeaderParagraph1:
        "Le CESU Domiserve a suscité votre intérêt et nous vous remercions de votre confiance.<br />Cet espace vous permet de commander en ligne vos CESU Domiserve (Titres CESU papier et/ou Compte e-CESU).",
    homeHeaderParagraph2:
        "Les commandes passées depuis votre espace apparaissent dans l’historique.<br />Cette rubrique vous permet également de suivre très facilement l’état d’avancement de vos commandes, et de télécharger vos factures.",
    homeHeaderParagraph3:
        "Pour plus d'informations, veuillez nous contacter à l'aide du lien de contact situé en bas de cet écran.",
    homeHeaderParagraph4Part1: "Nouveauté 2024 : ",
    homeHeaderParagraph4Part2: "Le plafond annuel d’exonération est porté à 2 421 € par année fiscale et par personne.",
    homeFooterText: "Pour plus d'informations, rendez-vous dans la rubrique Contact",

    // Images
    logo: "",
    homeHeaderImage: "",
    homeFooterImage: "",

    // COMMONS TEXTS
    mandatoryField: "* Champs obligatoires",

    // TEMPLATE
    templateTitreCESU: "",
    templateCompteCESU: "",

    // SIRET INFORMATION PAGE
    // Texts
    siretInformationDescription: "Saisissez le numéro de SIRET de votre société.",
    siretInformationSocietyTitle: "Société - Siret",
    siretInformationErrorMessageBigClient:
        "Le numéro de SIRET saisi ne permet pas de vous identifier. Nous vous invitons à contacter votre gestionnaire.",
    siretInformationErrorMessageExistingLittleClient:
        "Votre numéro de SIRET est connu par nos services. Pour récupérer vos identifiants, accédez à la rubrique ",
    siretInformationErrorMessage: "Une erreur a été rencontrée durant la validation du numéro SIRET.",

    // CONTACT INFORMATION PAGE
    // Texts
    contactInformationDescription:
        "Renseignez le formulaire ci-dessous et nous sauvegarderons vos informations pour vos prochaines commandes.",

    // USER INFORMATION COMPONENT
    // Texts
    userInformationResponsableTitle: "Responsable",
    userInformationResponsableInfo:
        '"Responsable" correspond à la personne en charge des commandes de CESU. Il/Elle recevra les identifiants de connexion à l’adresse email renseignée.',
    userInformationSocietyTitle: "Société",
    userInformationSocieteInfoPrefix:
        "Pour un changement de SIRET, veuillez nous contacter à l'aide du lien de contact situé en bas de cet écran.",
    userInformationAddressTitle: "Adresse",
    userInformationAddressInfo:
        '"Adresse" correspond à l’adresse de facturation et de livraison (dans le cas d’une commande de Titres CESU papier).',

    // ORDER PAGE
    // Texts
    orderCheckbookPopupText:
        'Chaque chéquier est composé de CESU de valeurs différentes allant de 15€ à 90€. Pour composer vous-même votre chéquier, cliquez sur "Autre".',
    orderFundedPartPopupText: "Il s’agit de la part financée par votre société.",
    orderCodePreferentiel: "Code préférentiel",
    orderInvalidCode: "Ce code n'est pas valide.",
    orderCardNotFilled:
        "Saisissez les champs obligatoires pour passer à l'étape suivante. " +
        "Pour mettre à jour la liste des bénéficiaires de la commande, vous avez la possibilité de retourner à l'étape 'Bénéficiaire'.",
    orderChampsObligatoires: "* Champs obligatoires",
    orderNextStep: "PASSER À L'ÉTAPE RÉCAPITULATIF",

    // ORDER HISTORY
    orderHistorySearchTitle: "Filtrer l'historique",
    orderDetailsInformationsTitle: "Informations principales",
    orderDetailsSummaryTitle: "Récapitulatif de la commande",
    orderDetailsSummaryButton: "Consulter les lignes de commande",
    orderDetailsInformationsButton: "Créer une Demande de Gestion",

    // BENEFICIARIES PAGE
    beneficiariesParagraphLittleClient:
        "En désactivant le lien sur mes bénéficiaires, ceux-ci n'apparaitront plus dans la liste " +
        "des bénéficiaires sélectionnables pour créer une commande.",
    beneficiariesParagraphBigClient:
        "En désactivant le lien sur mes bénéficiaires, ceux-ci n’auront plus accès aux sessions de précommandes en cours.",
    beneficiariesSearchTitle: "Rechercher un bénéficiaire",
    beneficiariesDetailsIdentityTitle: "Identité",
    beneficiariesDetailsAddressTitle: "Adresse",
    beneficiariesBigClientConfirmationActivationMessage:
        "Les bénéficiaires auront accès aux sessions de précommandes en cours",
    beneficiariesLittleClientConfirmationActivationMessage:
        "Les bénéficiaires apparaîtront dans la liste des bénéficiaires sélectionnables pour créer une commande",
    beneficiariesBigClientConfirmationDeactivationMessage:
        "Les bénéficiaires n’auront plus accès aux sessions de précommandes en cours",
    beneficiariesLittleClientConfirmationDeactivationMessage:
        "Les bénéficiaires n’apparaîtront plus dans la liste des bénéficiaires sélectionnables pour créer une commande",
    beneficiaryDetailsManagementRequestCreationButton: "Creér une demande de gestion",

    // INTERLOCUTOR ADMINISTRATION PAGE
    interlocutorAdministrationParagraph: "Voici la liste de vos gestionnaires",

    // INTERLOCUTOR ACCOUNT ACCESS PAGE
    interlocutorAccountAccessParagraph: "Retrouvez l'ensemble des bénéficiaires sous votre tutorat.",
    interlocutorAccountAccessActionLabel: "Accéder à l'espace bénéficiaire",

    // BENEFICIARIES ORDER PAGE
    // Texts
    beneficiariesOrderDescription:
        "Renseignez les bénéficiaires pour lesquels vous souhaitez passer une commande de CESU.",
    beneficiariesOrderDescriptionSecondLine:
        "Le bénéficiaire est la personne qui va utiliser les CESU pour régler des prestations de services à la personne.",
    beneficiariesOrderActiveTitle: "Bénéficiaires actifs",
    beneficiariesOrderChosenTitle: "Bénéficiaires de la commande",
    beneficiariesOrderFormTitle: "Ajouter un bénéficiaire",
    beneficiariesOrderRedirectionError:
        "Sélectionnez ou ajoutez au moins un bénéficiaire pour passer à l'étape suivante.",
    beneficiairiesOrderAgeError: (firstName, name) => `Le bénéficiaire
        ${firstName}
        ${name} n'a pas au moins 16 ans.`,
    beneficiariesOrderTooManyBeneficiaries: "Si vous souhaitez commander pour plus de 20 bénéficiaires,",
    beneficiariesOrderContactUs: "contactez-nous",
    beneficiariesOrderNextStep: "PASSER À L'ÉTAPE SUIVANTE",

    // ORDER OVERVIEW PAGE
    // Texts
    orderOverviewDescription:
        "Merci de vérifier votre commande ainsi que l'adresse de livraison avant de passer à l'étape Paiement.",
    orderOverviewConditionsError: "Veuillez accepter les conditions générales pour passer à l’étape suivante.",
    orderOverviewTooExpensiveError: "La limite d'une commande en ligne est de 100 000 euros.",
    orderOverviewBackendError: "Une erreur a été rencontré lors du calcul du récapitulatif.",
    orderOverviewTitleCesuAccount: "Compte e-CESU",
    orderOverviewTitleCesuPapier: "Titres CESU papier",
    orderOverviewTotalSoumisTva: "Total soumis à TVA",
    orderOverviewTotalNonSoumisTva: "Total commandé (Non soumis à la TVA)",
    orderOverviewFraisDeGestion: "Frais de gestion",
    orderOverviewRemise: "Remise",
    orderOverviewTotalHT: "Montant total HT",
    orderOverviewFraisDeLivraisonSurSite: "Frais de livraison sur site",
    orderOverviewMontantTva: "Montant TVA",
    orderOverviewMontantTotalTtc: "Montant total TTC",
    orderOverviewMontantTtcARegler: "MONTANT TTC À RÉGLER",
    orderOverviewPasserEtapePaiement: "PASSER À L'ÉTAPE PAIEMENT",

    mailOffersCheckboxMessage:
        "J’accepte de recevoir des offres commerciales, par voie électronique, de la part de Domiserve, des sociétés du Groupe auquel elle appartient, ses filiales, ses sous-traitants, partenaires et prestataires.",

    // ORDER PAYMENT PAGE
    // Texts
    orderPaymentDescription: "Sélectionnez votre moyen de paiement.",
    orderPaymentScelliusError: "Une erreur est survenue lors de la récupération des données.",
    orderPaymentError:
        "Votre paiement n’a pas pu aboutir. Pour finaliser votre commande, veuillez réessayer ou choisir un autre moyen de paiement.",
    orderPaymentCreditCardMessage: "Vous serez redirigé(e) vers notre solution de paiement sécurisée.",
    orderPaymentTransferMessage:
        "Veuillez préciser dans le motif du virement, le(s) numéro(s) de commande indiqué(s) dans l’e-mail de confirmation de commande.",
    orderPaymentMoyenPaiement: "Moyen de paiement",
    orderPaymentCarteBancaire: "Carte Bancaire",
    orderPaymentVirement: "Virement",
    orderPaymentFinaliser: "FINALISER",
    orderPaymentContinuer: "CONTINUER",
    orderPaymentPleaseWait: "Veuillez patienter pendant le traitement de votre commande",

    // ORDER CONFIRMATION PAGE
    // Texts
    orderConfirmationMerci: "Nous vous remercions pour votre commande de CESU !",
    orderConfirmationParagraph1:
        "Votre paiement a bien été enregistré, vous allez recevoir un email avec les informations relatives à votre commande.",
    orderConfirmationParagraph2:
        "Vous avez opté pour un règlement par virement, vous allez recevoir un email avec les informations nécessaires pour le règlement de celle-ci.",
    orderConfirmationParagraph3: "Vos Titres CESU papier vont être imprimés et vous parviendront sous 8 jours ouvrés.",
    orderConfirmationParagraph4:
        "Les Comptes e-CESU de vos collaborateurs seront alimentés sous 48h. Une confirmation d’alimentation leur sera transmise à l’adresse mail renseignée lors de la commande.",
    orderConfirmationParagraph5: "Dès réception de votre règlement : ",
    orderConfirmationParagraph5Li1:
        "Les Titres CESU papier vont être imprimés et vous parviendront sous 8 jours ouvrés.",
    orderConfirmationParagraph5Li2:
        "Les Comptes e-CESU de vos collaborateurs seront alimentés sous 48h, une confirmation d’alimentation leur sera transmise à l’adresse mail renseignée lors de la commande.",
    orderConfirmationParagraph6:
        "Dès réception de votre règlement, vos Titres CESU papier vont être imprimés et vous parviendront sous 8 jours ouvrés.",
    orderConfirmationParagraph7:
        "Dès réception de votre règlement, les Comptes e-CESU de vos collaborateurs seront alimentés sous 48h, une confirmation d’alimentation leur sera transmise à l’adresse mail renseignée lors de la commande.",
    orderConfirmationGarderNumeroClient1: "Nous vous invitons à garder précieusement votre numéro de Client",
    orderConfirmationGarderNumeroClient2: numClient => `(n°${numClient})`,
    orderConfirmationGarderNumeroClient3: "qui facilitera les échanges avec nos services.",
    orderConfirmationABientot: "A très bientôt sur domiserve.com.",

    // LOGIN PAGE
    // Texts
    loginNeedHelp: "Besoin d'aide ?",
    loginCreateAccount: "Vous souhaitez effectuer votre première commande de CESU en toute simplicité ?",
    loginCreateAccount2:
        "Merci de vous munir de votre numéro SIRET, de votre code APE/NAF et pour chaque salarié concerné : sa date de naissance, son adresse postale et son e-mail.",
    loginCreateAccount3: "COMMANDER DES CESU",
    loginContact: "Vous souhaitez commander pour plus de 20 bénéficiaires,",
    loginContactLink: "contactez nous.",
    loginSignUp: "Vous souhaitez vous inscrire à votre espace ?",
    loginClickCreateAccount: 'Cliquez sur "Je crée mon compte"',

    // RECIPIENTS PAGE
    recipientsParagraphLittleClient:
        "En désactivant le lien sur mes allocataires, ceux-ci n'apparaitront plus dans la liste " +
        "des allocataires sélectionnables pour créer une commande.",
    recipientsParagraphBigClient:
        "En désactivant le lien sur mes allocataires, ceux-ci n’auront plus accès aux sessions de précommandes en cours.",
    recipientsSearchTitle: "Rechercher un allocataire",
    recipientsDetailsIdentityTitle: "Identité",
    recipientsDetailsAddressTitle: "Adresse",
    recipientsBigClientConfirmationActivationMessage:
        "Les allocataires auront accès aux sessions de précommandes en cours",
    recipientsLittleClientConfirmationActivationMessage:
        "Les allocataires apparaîtront dans la liste des allocataires sélectionnables pour créer une commande",
    recipientsBigClientConfirmationDeactivationMessage:
        "Les allocataires n’auront plus accès aux sessions de précommandes en cours",
    recipientsLittleClientConfirmationDeactivationMessage:
        "Les allocataires n’apparaîtront plus dans la liste des allocataires sélectionnables pour créer une commande",

    // RECOVER LOGIN PASSWORD PAGE
    // Texts
    recoverLoginPasswordSuccessHeader: "Demande prise en compte",
    recoverLoginPasswordSuccessParagraphLine1:
        "Si vous êtes déjà inscrit à l’espace, et que vous commandez pour moins de 20 bénéficiaires, un email vous a été envoyé à l’adresse renseignée sur votre profil.",
    recoverLoginPasswordSuccessParagraphLine2: " Vous souhaitez vous inscrire ? Cliquez sur ",
    recoverLoginPasswordSuccessParagraphLine2Registration: "« COMMANDER DES CESU »",
    recoverLoginPasswordSuccessParagraphLine3:
        "Si vous commandez des CESU pour plus de 20 bénéficiaires, nous vous invitons à contacter votre interlocuteur dédié Domiserve.",

    recoverLoginPasswordFormUsername: "Saisissez votre nom d'utilisateur",
    recoverLoginPasswordFormSiret: "Saisissez votre numéro de SIRET",
    recoverLoginPasswordButton: "Envoyer mes identifiants",
    recoverLoginPasswordConnection: "Connexion",

    // RESET PASSWORD PAGE
    resetPasswordTokenText: "Cette opération a déjà été réalisée. Lien expiré.",
    resetPasswordSuccessText: "Votre mot de passe a bien été changé. Vous allez être redirigé dans 5 secondes.",

    // ORDER DEPOSIT PAGE
    // Texts
    orderDepositSelectProducts: "Veuillez sélectionner les produits concernés par la commande *",
    orderDepositNoProducts: "Il n'y a pas de produit pour ce contrat",
    orderDepositDownloadPurchaseOrder: "Télécharger un bon de commande",
    orderDepositOrderValidationMandatory:
        "Afin de valider votre commande, veuillez confirmer les informations suivantes :",

    // MANAGEMENT REQUEST PAGE
    // Texts
    managementRequestDescription: "Vous pouvez compléter ici votre demande de gestion.",
    managementRequestCommentConfirmation:
        "Attention, aucun commentaire n'a été ajouté à la demande de gestion, voulez-vous poursuivre ?",

    // PREORDER SESSION PAGES
    // Texts
    // Init
    preOrderSessiontInitDescription:
        "Définissez la période d'ouverture de votre session de précommande et choisissez le format de CESU (Titre papier et/ou Compte électronique) que vos collaborateurs pourront commander.",
    preOrderSessionInitPermanent:
        "En cas de session permanente, les collaborateurs peuvent commander quand ils le souhaitent pendant l'année en cours. Le financeur valide les commandes au fil de l'eau.",
    createPreOrderSessionNoCheckbookProducts: "Pas de produits titres",
    createPreOrderSessionNoAccountProducts: "Pas de produits comptes",

    preOrderSessionCheckbookFacialValueRestriction: "Fixer la valeur faciale des CESU Papier ?",
    preOrderSessionCheckbookPreOrderRestriction: "Fixer les valeurs possibles de précommande ?",
    preOrderSessionAccountServiceTypeLimitation: "Limiter l'usage des comptes CESU à un type de prestation ?",

    // Participation
    preOrderSessionParticipation: "Veuillez définir votre participation et le montant maximum de précommande autorisé",
    preOrderSessionParticipationSessionGroupInfo:
        "Cette fonction vous permet de lier plusieurs sessions entre elles. Si vous spécifiez un groupe de session, l'abondement non consommé sur les sessions précédentes est reporté sur les sessions suivantes du même groupe.",
    preOrderSessionParticipationNoStaffCategory:
        "Vous n’avez sélectionné aucune catégorie de personnel dans le tableau de définition de la participation Financeur. Veuillez sélectionner au moins une catégorie de personnel.",
    preOrderSessionParticipationInvalidStaffCategory:
        "Une de vos catégories est incomplète. Veuillez la compléter ou l'annuler.",
    preOrderParticipationCardInvalidSlice:
        "Somme des participations par tranches supérieure au montant de participation maximum.",

    // Control
    preOrderSessionControlFile:
        "Souhaitez vous limiter l'accès de cette session de précommande à une liste de salariés ?",
    preOrderSessionControlComplementary:
        "Souhaitez vous demander une ou plusieurs informations supplémentaires à vos salariés ?",
    preOrderSessionControlFieldsChoice:
        "Veuillez sélectionner le ou les champs de contrôle lors de l'inscription ou de la précommande du collaborateur.",
    preOrderSessionControlFieldIdentifierChoice:
        "Veuillez sélectionner le champ de contrôle permettant d'identifier le collaborateur lors de l'inscription (ce champ ne doit pas comporter de doublons)",
    preOrderSessionControlAmount:
        "Vous avez la possibilité de limiter l’accès de cette session à une liste de salariés.",
    preOrderSessionControlAmount1:
        "Pour cela, il est nécessaire d’établir un fichier de contrôle comprenant une information unique par bénéficiaire (ex : matricule salarié), avec ou sans montant maximum de précommande et de participation financeur.",
    preOrderSessionControlAmount2:
        "Attention, si des montants maximum de précommande et de participation sont inclus au fichier de contrôle, les précommandes des salariés seront vérifiées selon ces montants.",
    preOrderSessionControlAmount3:
        "Si le fichier de contrôle n’en contient pas, les précommandes des salariés seront vérifiées selon les informations définies à l’étape précédente “Participation”.",
    preOrderSessionControlFileInvalid: "Le fichier de contrôle est invalide",

    // Communication
    preOrderSessionCommunicationAlertSessionStart:
        "Je souhaite avertir mes collaborateurs déjà inscrits par mail du démarrage de la session",
    preOrderSessionCommunicationAlertSessionEnd:
        "Je souhaite avertir par mail mes collaborateurs déjà inscrits et/ou chargés qui n'ont pas encore commandé, de la fin imminente de la période de session",
    preOrderSessionCommunicationIntroductionMessage:
        "Je souhaite afficher un message introductif lors de la commande de mes collaborateurs sur cette session",
    preOrderSessionCommunicationConfirmationMessage:
        "Je souhaite afficher un message de confirmation à la fin de la commande des collaborateurs",
    // Delivery
    preOrderSessionDeliveryPickDelivery: "Veuillez choisir le lieu de livraison des commandes de CESU Papier.",
    preOrderSessionDeliveryAtBeneficiaryHome:
        "Je souhaite que mes bénéficiaires reçoivent leurs CESU Papier directement à leur domicile",
    preOrderSessionDeliveryAtTierceAddress:
        "Je souhaite laisser à mes bénéficiaires le choix du lieu de livraison de leurs CESU Papier à une adresse tierce",
    preOrderSessionDeliveryPoint: "Je souhaite recevoir l'ensemble des CESU Papier à l'un de mes points de livraison",

    // Management
    preOrderSessionManagementDescription: "Retrouvez ici vos sessions de précommande.",
    preOrderSessionManagementToggleButton: "Afficher les sessions de précommande cloturées.",
    preOrderSessionManagementClosingWarning: (nbValidatedLines, nbWaitingLines) =>
        "Attention, si vous clôturez la session, les " +
        nbValidatedLines +
        " pré-commandes validées ou " +
        nbWaitingLines +
        " à valider de vos bénéficiaires ne seront pas prises en compte. Elles seront annulées.",
    preOrderSessionManagementSessionClosed: "La session de précommande est maintenant clôturée.",
    preOrderSessionManagementOrderCreated: "Vous pouvez retrouver vos commandes dans l'historique.",

    preOrderSessionSumUpDescription: "Accédez au bilan de votre session de précommande.",

    preOrderSessionLinesManagementDescription: "Gérez vos précommandes et regroupez les en commandes.",

    // Confirmation
    preOrderSessionConfirmation:
        "Elle est accessible via l'Espace Bénéficiaire. Vos collaborateurs pourront y accéder à l'adresse suivante : ",

    // REPORTS PAGE
    // Texts
    reportsDescription: "Consultez, ajoutez ou retirez des rapports.",
    reportsSelectedFolder: "Dossier sélectionné : ",
    reportsRootSelected: "Le dossier racine est actuellement sélectionné.",
    reportsAddSubfolder: "Ajouter un sous dossier",
    reportsAddFile: "Déposer un fichier",
    reportsError: "Une erreur a été rencontrée lors de la récupération de vos rapports.",

    // ERROR PAGE
    technicalErrorText: "Il semblerait qu'une erreur technique se soit produite.",

    // NOT FOUND PAGE
    // Texts
    notFoundText: "Page introuvable.",

    // UNAUTHORIZED PAGE
    // Texts
    unauthorizedText: "Oups, vous vous êtes perdu ?",
}

/**
 * If you want to override defaults texts for big clients
 * Do it here
 */
export const defaultsTextsBigClient = {
    // BigClient default texts
    homeHeaderTitle: "Bienvenue sur votre extranet financeur Domiserve",
    homeHeaderParagraph1:
        "Cet espace vous permet de préparer et d’adresser vos commandes de CESU à Domiserve en toute sécurité. <br/>Deux modes de commande existent :",
    homeHeaderParagraph2:
        "<ul><li><b>Les sessions de commandes en ligne</b><br>Confiez la saisie de la commande à vos collaborateurs.<br> Une fois leurs précommandes validées par vos soins, constituez une commande globale en quelques clics. Celle-ci sera automatiquement transmise à Domiserve pour traitement !<br><li><b>Les commandes par fichier Excel</b><br>Téléchargez votre bon de commande Excel, puis renseignez pour chacun de vos collaborateurs le contenu de la commande. <br>Une fois votre fichier de commande constitué, transmettez-le à Domiserve pour traitement.",
    homeHeaderParagraph3:
        "Dans les deux cas, toutes vos commandes adressées à partir de votre espace eDomiserve sont disponibles dans votre historique. Cette rubrique vous permet également de suivre très facilement l'état d'avancement de vos commandes.",
    homeFooterText: "Pour plus d’informations, n’hésitez pas à solliciter vos interlocuteurs dédiés Domiserve.",
}

let texts = {...defaultTexts}

export function updateGlobalTexts(newTexts) {
    texts = Object.assign(texts, newTexts)
}

export default texts
